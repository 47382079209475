import { ETopLiveDropRarities } from './topLiveDrop.types';
import type {
  ITopLiveDropWSItem,
  ITopLiveDropApiData,
  ITopLiveDropItem,
  ITopLiveDropModificator,
} from './topLiveDrop.types';

import { WEBSOCKET_FIRST_CHANNEL } from '~/constants/app.constants';
import { Colors } from '~/constants/colors.constants';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useAlertStore } from '~/store/alert/alert.store';

export const MAX_ELEMENTS = 20;
const VARIANTS = {
  battle: {
    color: Colors.GRADIENTS.LIVEDROPBATTLES,
    icon: 'SvgoBattlesIcon',
  },
  contract: {
    color: Colors.GRADIENTS.LIVEDROPCONTRACT,
    icon: 'SvgoContractIcon',
  },
  upgrade: {
    color: Colors.GRADIENTS.LIVEDROPUPGRADE,
    icon: 'SvgoUpgradeIcon',
  },
};
const WEBSOCKET_EVENTS = ['lastwinner', 'topdrop'];

export const useTopLiveDropStore = defineStore('global/topLiveDrop', () => {
  const { $api } = useNuxtApp();

  const liveDrops = ref<ITopLiveDropItem[]>([]);
  const topDrops = ref<ITopLiveDropItem[]>([]);

  const isActiveLive = ref(true);
  const isLoaded = ref(false);

  const activeList = computed(() => {
    const temp = isActiveLive.value ? liveDrops.value : topDrops.value;

    return temp.sort((a, b) => {
      if (a.isUltraRare === b.isUltraRare) return 0;
      return a.isUltraRare ? 1 : -1;
    });
  });

  const fetch = async () => {
    try {
      const data = await $api.dropFeed.getTopDrop(!isActiveLive.value);
      const parsedData = parseData(data);

      if (isActiveLive.value) {
        liveDrops.value = parsedData;
      } else {
        topDrops.value = parsedData;
      }
    } catch (e) {
      useAlertStore().showError({
        message: (e as { msg: string | undefined }).msg || '',
        title: ErrorCodes.UNPREDICTED_EXCEPTION,
      });
    } finally {
      isLoaded.value = true;
    }
  };

  let unsubscibe: () => boolean;

  const subscribe = () => {
    unsubscibe = $api.websocket.subscribe(WEBSOCKET_FIRST_CHANNEL, {
      cb: (data) => {
        const parsedMsg = parseWSData(JSON.parse(data.msg));

        const listToAdd = data.event === 'topdrop' ? topDrops : liveDrops;
        listToAdd.value.unshift(parsedMsg);

        if (listToAdd.value.length > MAX_ELEMENTS) {
          listToAdd.value = listToAdd.value.slice(0, MAX_ELEMENTS);
        }
      },
      event: WEBSOCKET_EVENTS,
      uniqueKey: 'LiveDrops/DropItems',
    });
  };

  const parseData = (data: ITopLiveDropApiData[]): ITopLiveDropItem[] => {
    const result: ITopLiveDropItem[] = [];

    for (const item of data) {
      const { additionalData, itemData, userData } = item;
      const rarity = isRarityCorrect(itemData.quality.toLowerCase())
        ? (itemData.quality.toLowerCase() as ETopLiveDropRarities)
        : ETopLiveDropRarities.COMMON;

      result.push({
        id: getUniqueId(),
        images: {
          default: itemData.image,
          hover: itemData.imageHover,
        },
        isUltraRare: itemData.isUltraRare,
        modificator: additionalData ? getModificatorOfItem(additionalData.percent, itemData.type) : undefined,
        rarity,
        user: {
          id: userData.id,
          nickname: userData.name,
        },
      });
    }

    return result;
  };

  const parseWSData = (data: ITopLiveDropWSItem): ITopLiveDropItem => {
    const rarity = isRarityCorrect(data.quality.toLowerCase())
      ? (data.quality.toLowerCase() as ETopLiveDropRarities)
      : ETopLiveDropRarities.COMMON;

    const type = data.gamename?.toLowerCase().includes('battle') ? 'battle' : data.casetype;

    return {
      id: `${data.id}`,
      images: {
        default: getFullImgUrl(data.image),
        hover: getFullImgUrl(data.caseimg),
      },
      isUltraRare: false,
      modificator: getModificatorOfItem(data.percent, type),
      rarity,
      user: {
        id: +data.userid,
        nickname: data.username,
      },
    };
  };

  const getModificatorOfItem = (percent: string, type: string): ITopLiveDropModificator | undefined => {
    const variantKey = type.toLowerCase();
    if (!(variantKey in VARIANTS)) return undefined;

    const variant = VARIANTS[variantKey as keyof typeof VARIANTS];
    if (variantKey === 'upgrade' && percent) {
      const parsedFloat = parseFloat(percent);
      return {
        ...variant,
        text: `${+GlobalUtils.Numbers.toFixed(parsedFloat, 3)}%`,
      };
    }

    return variant;
  };

  const isRarityCorrect = (rare: string) => Object.values(ETopLiveDropRarities).includes(rare as ETopLiveDropRarities);

  const getUniqueId = () => {
    const prefix = isActiveLive.value ? 'live' : 'top';
    const firstPart = Math.random().toString(36).slice(2, 16);
    const secondPart = Math.random().toString(36).slice(2, 16);

    return `${prefix}-${firstPart}-${secondPart}`;
  };

  const getFullImgUrl = (image: string) => `${useLinks().domen}/${image}`;

  onBeforeMount(async () => {
    await fetch();
    subscribe();
  });
  onBeforeUnmount(() => unsubscibe?.());

  watch(isActiveLive, async (value) => {
    const isEmptyLive = value && !liveDrops.value.length;
    const isEmptyTop = !value && !topDrops.value.length;

    if (!isEmptyLive && !isEmptyTop) return;
    await fetch();
  });

  return {
    activeList,
    isActiveLive,
    isLoaded,
  };
});
